import styled, { css } from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';
import { Col, Row } from 'components/Core/Grid';

export const SectionRow = styled(Row)`
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-top: ${({ $showBreadcrumbs }) => ($showBreadcrumbs ? '60px' : '0px')};
  .data-layer-form {
    justify-content: flex-start;
  }

  .hero-featured-content--bottom {
    margin-top: -175px;

    @media (max-width: 767px) {
      margin-top: -130px;
    }

    @media (max-width: 479px) {
      margin-top: -90px;
    }
  }

  @media (max-width: 1450px) {
    padding-top: 0;
  }
`;

export const SectionCol = styled(Col)`
  align-items: ${({ $contentLeftAligned }) => $contentLeftAligned && 'start !important'};
  height: inherit;

  .hero--wistia-video {
    width: 100%;
    overflow: hidden;
  }
  .hero-featured-content--center-vertically {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    .hero-featured-content--content {
      width: 100%;
    }
  }

  @media (max-width: 991px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  @media (max-width: 575px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 20px !important;
    width: 100%;
  }

  &.hero-featured-content--col-full-bleed {
    height: 600px;
    padding-left: 0 !important;

    .hero-featured-content--content,
    .hero-featured-content--side {
      height: 100%;
      overflow: visible;
    }

    @media (max-width: 575px) {
      height: 100%;

      .hero-featured-content--content {
        overflow: hidden;
      }
    }
  }
`;

const blueRoundedCornerKicker = css`
  background: var(--titan-blue-3);
  border-radius: 50px;
  padding: 8px 14px 4px 14px;
  width: max-content;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const StyledSectionLayout = styled(SectionLayout)`
  padding-top: 192px;
  background-image: ${({ backgroundImg }) => backgroundImg && `url(${backgroundImg})`};
  background-color: ${({ bgColor }) => bgColor && `var(${bgColor})`};
  background-size: cover;
  overflow: hidden;

  .dynamic-hero-bottom-featured-content--inner-container {
    padding-bottom: 150px;
  }

  .hero-copy {
    text-align: ${({ $contentLeftAligned }) => $contentLeftAligned && 'left'};
    margin: ${({ $contentLeftAligned }) => !$contentLeftAligned && '0 auto'};
    max-width: 960px;

    .hero-copy-kicker {
      ${({ $kickerStyle }) => {
        if ($kickerStyle === 'Blue - Rounded Corners') {
          return blueRoundedCornerKicker;
        }
      }}
    }

    .hero-copy-title {
      font-size: var(--font-size-44);
      font-weight: var(--font-weight-900);
      line-height: var(--line-height-110);
      letter-spacing: var(--letter-spacing--0-05);
    }
    .hero-copy-title:not(:last-child) {
      margin-bottom: 0px;
    }
    .hero-copy-description {
      font-size: var(--font-size-p-md);
      margin: 0;
      padding: 0;
      margin-top: 20px;
    }
    .hero-copy-scribe--form {
      margin-top: 40px;
    }
  }

  .ctaButton {
    margin-top: 40px;
  }

  @media (max-width: 1199px) {
    padding-top: ${({ $showBreadcrumbs }) => ($showBreadcrumbs ? '140px' : '100px')} !important;
  }

  @media (max-width: 991px) {
    .hero-copy {
      .hero-copy-title {
        font-size: var(--font-size-35);
      }
      .hero-copy-description {
        font-size: var(--font-size-18);
      }
    }
  }

  @media (max-width: 767px) {
    .hero-copy {
      .hero-copy-title {
        font-size: var(--font-size-28);
      }
      .hero-copy-description {
        font-size: var(--font-size-16);
        margin-bottom: 0px;
      }
      .social-proof-content {
        margin-top: 15px;
      }

      .social-proof-logo-content {
        display: flex;
        align-items: center;
        margin-top: 28px;

        .social-proof-logo-item {
          margin-right: 27px;

          &:last-child {
            margin-right: 0;
          }

          img {
            max-width: 100%;
          }
        }
      }
    }
    .dynamic-hero-bottom-featured-content--inner-container {
      padding-bottom: 130px;
    }
  }

  @media (max-width: 680px) {
    ${({ $showBreadcrumbs }) => $showBreadcrumbs && 'padding-top: 200px;'};
    .hero-copy {
      .hero-copy-title {
        font-size: var(--font-size-35);
      }
      .hero-copy-description {
        font-size: var(--font-size-20);
        margin-top: 21px;
      }
      .social-proof-content {
        margin-top: 15px;
      }

      .social-proof-logo-content {
        display: flex;
        align-items: center;
        margin-top: 28px;

        .social-proof-logo-item {
          margin-right: 27px;

          &:last-child {
            margin-right: 0;
          }

          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    ${({ $reverseMobileOrder }) => {
      if ($reverseMobileOrder) {
        return `
            .hero-featured-content--col {
              margin-bottom: 20px;
            }
        `;
      }
    }}
  }

  @media (max-width: 479px) {
    background-image: ${({ bgImage }) => bgImage && `url(${bgImage})`};

    .dynamic-hero-bottom-featured-content--inner-container {
      padding-bottom: 70px;
    }
  }
`;

export const DemoAndSeePreviewContainer = styled.div`
  display: flex;
  align-items: ${(props) => (props.isInline ? 'center' : 'flex-start')};
  flex-direction: ${(props) => (props.isInline ? 'row' : 'column')};

  .hero-contextual-cta--origin {
    width: ${(props) => (props.isInline ? 'auto' : '100%')};
  }

  .wistia-video--wrapper {
    .play-label {
      cursor: pointer;
      margin-top: ${(props) => (props.isInline ? '40px' : '20px')};
      margin-left: ${(props) => (props.isInline ? '10px' : '0')};
    }
  }
`;
